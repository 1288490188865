import { computed } from 'vue';
import { access, community } from '@/data';
import { ListHeaderItem, RequestData } from '@/components/common/list';
import changeTextByScene from '@/methods/setText';
import { AllowType } from './all.type';

changeTextByScene(community.ProjectType.value);

const requestData = computed<{
    [key in AllowType]: RequestData
}>(() => ({
    self: {
        url: `v3/web/${community.ProjectType.value}/device/getUserAccessDevices`,
        param: {
            ID: ''
        }
    },
    common: {
        url: community.ProjectType.value === 'community' ? 'v3/web/community/device/getPubListForPm' : 'v3/web/office/device/getPubListForPm',
        param: {}
    }
}));
const { repeatLabels, weekList } = access;

const getFormRules = (type: string) => {
    if (type === '0') {
        return {
            Name: [{
                required: true,
                message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextAccessGroupName),
                trigger: 'blur'
            }],
            StartDay: [{
                type: 'date',
                required: true,
                message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlBeginTime),
                trigger: 'blur'
            }],
            StopDay: [{
                type: 'date',
                required: true,
                message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
                trigger: 'blur'
            }]
        };
    }

    return {
        Name: [{
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextAccessGroupName),
            trigger: 'blur'
        }],
        StartTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TmpKeyManageListTanleBeginTime),
            trigger: 'blur'
        }],
        StopTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }]
    };
};

type ProjectDataType = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
    };
}
const getProjectData = () => {
    const communityHeader: Array<ListHeaderItem> = [{
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'Relay',
        label: WordList.ProperAllTextRelay,
        type: 'customize',
        headerType: 'customize'
    }];
    const officeHeader: Array<ListHeaderItem> = [{
        name: 'UnitName',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'Relay',
        label: WordList.ProperAllTextRelay,
        type: 'customize',
        headerType: 'customize'
    }];
    const projectData: ProjectDataType = {
        community: {
            header: communityHeader
        },
        office: {
            header: officeHeader
        }
    };
    return projectData;
};
export default null;

export {
    requestData,
    repeatLabels,
    weekList,
    getFormRules,
    getProjectData
};
