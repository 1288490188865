
import {
    defineComponent, PropType, reactive, ref,
    computed, Ref, watch
} from 'vue';
import { list, ListHeaderItem } from '@/components/common/list';
import { access, community } from '@/data';
import initCheck from '@/methods/relay-choose';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import { format } from '@/methods/date';
import HttpRequest from '@/util/axios.config';
import Notice from '@/util/notice.base';

import { ElMessageBox } from 'element-plus';
import { FormData, FormResult, AllowType } from './all.type';
import {
    getFormRules, repeatLabels, weekList,
    requestData, getProjectData
} from './util';

export default defineComponent({
    emits: ['success', 'getForm', 'checkValid'],
    components: {
        list,
        deviceIcon,
        deviceStatus
    },
    props: {
        // 房间Id
        id: {
            type: [String, null] as PropType<string | null>,
            default: null
        },
        commit: {
            type: Number
        },
        type: {
            type: String as PropType<AllowType>,
            default: 'common'
        },
        initForm: {
            type: Object as PropType<FormResult>
        },
        // 办公人员id
        pid: {
            type: [String, null] as PropType<string | null>,
            default: null
        }
    },
    setup(props, { emit }) {
        const requestDataType = requestData.value[props.type];
        if (props.type === 'self' && props.id) requestDataType.param.ID = props.id;
        if (props.type === 'self' && props.pid) requestDataType.param.ID = props.pid;
        const formRef: Ref<any> = ref();
        const formData: FormData = reactive({
            Name: '',
            SchedulerType: '1',
            StartDay: '',
            StopDay: '',
            StartTime: new Date('2021-01-01 00:00:00'),
            StopTime: new Date('2021-01-01 23:59:59'),
            DateFlag: []
        });

        const rules = ref(getFormRules(formData.SchedulerType));
        watch(computed(() => formData.SchedulerType), () => {
            rules.value = getFormRules(formData.SchedulerType);
        });
        // data.Grade 1为公共设备，2为楼栋公共设备
        const {
            allCheck, setCheck, checkArray, dealList, choose, getCommit, changeAllCheck, checkArrayFilter, alreadySelectPubDevice,
            alreadySelectBuildDevice, allDeviceList
        } = initCheck(
            props.type === 'common' && props.id ? (data) => {
                if (data.Grade === '2') return true;
                return false;
            } : () => false
        );

        const changeSchedulerType = (value: string) => {
            if (value === '0') {
                formData.StartDay = '';
                formData.StopDay = '';
            } else {
                formData.StartTime = new Date('2021-01-01 00:00:00');
                formData.StopTime = new Date('2021-01-01 23:59:59');
            }
        };

        const update = ref(0);
        if (props.type === 'common' && props.id) {
            access.getPropertyAccessInfo(props.id, (data: FormResult) => {
                formData.Name = data.Name;
                formData.SchedulerType = data.SchedulerType;
                formData.StartDay = data.StartDay;
                formData.StopDay = data.StopDay;
                formData.StartTime = new Date(`2020-01-01 ${data.StartTime}`);
                formData.StopTime = new Date(`2020-01-01 ${data.StopTime}`);
                formData.DateFlag = data.DateFlag.split(';');
                setCheck(data.Device, update);
            });
        } else if (props.type === 'self' && props.initForm) {
            watch(computed(() => props.initForm), () => {
                const data = props.initForm!;
                formData.Name = data.Name;
                formData.SchedulerType = data.SchedulerType;
                formData.StartDay = data.StartDay;
                formData.StopDay = data.StopDay;
                formData.StartTime = new Date(`2020-01-01 ${data.StartTime}`);
                formData.StopTime = new Date(`2020-01-01 ${data.StopTime}`);
                formData.DateFlag = data.DateFlag.split(';');
                setCheck(data.Device, update);
            }, {
                immediate: true
            });
        }

        const submit = () => {
            // 编辑前已经有的楼栋名
            const buildNameSubmitListAlready: string[] = [];
            alreadySelectBuildDevice.forEach((item) => {
                // 当前设备的UnitName是否已经存在buildNameSubmitListAlready里面
                const isAlreadExit = buildNameSubmitListAlready.some((el) => el === item.UnitName);
                if (!isAlreadExit) {
                    buildNameSubmitListAlready.push(item.UnitName);
                }
            });
            // 提交的楼栋设备
            const buildNameSubmitList: string[] = [];
            Object.keys(checkArrayFilter.value).forEach((key) => {
                const index = allDeviceList.value.findIndex((item) => item.MAC === key);
                // 当前设备的UnitName是否已经存在buildNameSubmitList里面
                const isAlreadExit = buildNameSubmitList.some((item) => item === allDeviceList.value[index].UnitName);
                if ((checkArrayFilter.value[key].Relay.length > 0 || checkArrayFilter.value[key].Security.length > 0) && !isAlreadExit) {
                    buildNameSubmitList.push(allDeviceList.value[index].UnitName);
                }
            });
            // eslint-disable-next-line max-len
            const getArrDifference = (arr1: string[], arr2: string[]) => arr1.concat(arr2).filter((v, i, arr) => arr.indexOf(v) === arr.lastIndexOf(v));
            // 不能取消勾选 所有的Building设备，一定要留一个Building设备
            const loseBuildNameList = getArrDifference(buildNameSubmitListAlready, buildNameSubmitList);

            if (loseBuildNameList.length > 0) {
                ElMessageBox.alert(
                    community.ProjectType.value === 'community'
                        ? WordList.PleaseSelectAtLeastOneDeviceOfXBuilding.format(loseBuildNameList[0])
                        : WordList.PleaseSelectAtLeastOneDeviceOfXDepartment.format(loseBuildNameList[0]),
                    WordList.Notice,
                    { type: 'error', confirmButtonText: WordList.TabelFootSure }
                );
                return;
            }
            // if(alreadySelectPubDevice)
            const devices = getCommit();
            if (devices.length === 0) {
                Notice.messageBox('alert', WordList.RuleMacChoose, WordList.PersonuserInfo, 'info')(() => false);
                return;
            }
            const url = !props.id ? `v3/web/${community.ProjectType.value}/access/add` : `v3/web/${community.ProjectType.value}/access/edit`;
            formRef.value.validate((valid: boolean) => {
                const param = { ...formData };
                param.StartDay = format(param.StartDay, 'YYYY-MM-DD');
                param.StopDay = format(param.StopDay, 'YYYY-MM-DD');
                param.StartTime = format(param.StartTime, 'HH:mm:ss');
                param.StopTime = format(param.StopTime, 'HH:mm:ss');
                if (valid) {
                    if (typeof param.DateFlag !== 'string') param.DateFlag = param.DateFlag.join(';');
                    if (props.type === 'self') {
                        emit('checkValid', true);
                        emit('getForm', { ...param, Device: devices });
                    } else {
                        HttpRequest.post(`${url}`, { ...param, Device: devices, ID: props.id || '' }, () => {
                            emit('success');
                        });
                    }
                } else if (props.type === 'self') {
                    emit('checkValid', false);
                }
            });

            return true;
        };

        watch(computed(() => props.commit), submit);

        const headers: Ref< Array<ListHeaderItem> > = ref([]);
        watch(community.ProjectType, () => {
            const projectData = getProjectData()[community.ProjectType.value];
            headers.value = projectData.header;
        }, {
            immediate: true
        });

        return {
            repeatLabels,
            weekList,
            formRef,
            formData,
            rules,
            headers,
            requestDataType,
            allCheck,
            checkArray,
            dealList,
            choose,
            changeAllCheck,
            checkArrayFilter,
            changeSchedulerType,
            update
        };
    }
});
