import { ref, Ref, reactive } from 'vue';

type CheckArray = { [key in string]: {
    Relay: string[];
    Security: string[];
} };

const init = (
    filter: (data?: any) => boolean = () => false
) => {
    const allCheck = ref(false);
    // 完整的设备数据
    const allDeviceList = ref<Array<{ MAC: string; Relay: string; SecurityRelay?: string; UnitName: any}>>([]);
    // 添加时候勾选的公共设备数据，处理成key为mac，value为relay和Security数组
    const checkArray: Ref< CheckArray > = ref({});
    const alreadySelectPubDevice: any[] = [];
    let result: Array<{
        MAC: string;
        Relay: string | { key: string; label: string }[];
        SecurityRelay?: string | { key: string; label: string }[];
    }> = [];
    // 添加时候勾选的楼栋设备数据，处理成key为mac，value为relay和Security数组
    const checkArrayFilter: Ref< CheckArray > = ref({});
    const alreadySelectBuildDevice: any[] = [];
    let resultFilter: Array<{
        MAC: string;
        Relay: string | { key: string; label: string }[];
        SecurityRelay?: string | { key: string; label: string }[];
    }> = reactive([]);

    // 点击触发全选
    const changeAllCheck = () => {
        result.forEach((item) => {
            checkArray.value[item.MAC] = {
                Relay: [],
                Security: []
            };
            if (allCheck.value === true) {
                if (typeof item.Relay !== 'string') {
                    item.Relay.forEach((relay) => {
                        checkArray.value[item.MAC].Relay.push(relay.key);
                    });
                }
                if (item.SecurityRelay && typeof item.SecurityRelay !== 'string') {
                    item.SecurityRelay.forEach((relay) => {
                        checkArray.value[item.MAC].Security.push(relay.key);
                    });
                }
            }
        });
        resultFilter.forEach((item) => {
            checkArrayFilter.value[item.MAC] = {
                Relay: [],
                Security: []
            };
            if (allCheck.value === true) {
                if (typeof item.Relay !== 'string') {
                    item.Relay.forEach((relay) => {
                        checkArrayFilter.value[item.MAC].Relay.push(relay.key);
                    });
                }
                if (item.SecurityRelay && typeof item.SecurityRelay !== 'string') {
                    item.SecurityRelay.forEach((relay) => {
                        checkArrayFilter.value[item.MAC].Security.push(relay.key);
                    });
                }
            }
        });
    };

    // 设置初始化参数，需要update后再次触发dealList
    const setCheck = (device: Array<{ MAC: string; Relay: string; SecurityRelay?: string }>, update: Ref<number>) => {
        console.log('device', device);
        device.forEach((item) => {
            if (filter(item)) {
                checkArrayFilter.value[item.MAC] = {
                    Relay: [],
                    Security: []
                };
                alreadySelectBuildDevice.push(item);
                // item.Relay;拼接的字符串
                const relay = item.Relay.split(';');
                relay.forEach((relayItem) => {
                    if (relayItem !== '') {
                        checkArrayFilter.value[item.MAC].Relay.push(relayItem);
                    }
                });
                if (item.SecurityRelay) {
                    // item.SecurityRelay;拼接的字符串
                    const security = item.SecurityRelay.split(';');
                    security.forEach((relayItem) => {
                        checkArrayFilter.value[item.MAC].Security.push(relayItem);
                    });
                }
            } else {
                checkArray.value[item.MAC] = {
                    Relay: [],
                    Security: []
                };
                alreadySelectPubDevice.push(item);
                // item.Relay;拼接的字符串
                const relay = item.Relay.split(';');
                relay.forEach((relayItem) => {
                    if (relayItem !== '') {
                        checkArray.value[item.MAC].Relay.push(relayItem);
                    }
                });
                if (item.SecurityRelay) {
                    // item.SecurityRelay：名字和;拼接的字符串
                    const security = item.SecurityRelay.split(';');
                    security.forEach((relayItem) => {
                        checkArray.value[item.MAC].Security.push(relayItem);
                    });
                }
            }
        });
        // alreadySelectBuildDevice = JSON.parse(JSON.stringify(checkArrayFilter))
        // alreadySelectPubDevice = JSON.parse(JSON.stringify(checkArray))

        // eslint-disable-next-line no-param-reassign
        update.value += 1;
        // let all = true;
        // // 如果是先dealList，result才能进入foreach
        // result.forEach((item) => {
        //     if (checkArray.value[item.MAC].length !== item.Relay.length) all = false;
        // });
        // allCheck.value = all;
    };

    // 在列表数据请求完成后，进行check初始化
    const dealList = (data: Array<{ MAC: string; Relay: string; SecurityRelay?: string; UnitName: any}>) => {
        allDeviceList.value = data;
        // 设置UnitName，后期迁移建议接口加上UnitName字段
        alreadySelectBuildDevice.forEach((el, index) => {
            const dataIndex = data.findIndex((d) => d.MAC === el.MAC);
            // 避免修改设备类型出错
            if (dataIndex !== -1) {
                alreadySelectBuildDevice[index].UnitName = data[dataIndex].UnitName;
            }
        });
        alreadySelectPubDevice.forEach((el, index) => {
            const dataIndex = data.findIndex((d) => d.MAC === el.MAC);
            // 避免修改设备类型出错
            if (dataIndex !== -1) {
                alreadySelectPubDevice[index].UnitName = data[dataIndex].UnitName;
            }
        });
        result = [];
        resultFilter = [];
        let all = true;
        let chooseRelayLength = 0;
        data.forEach((item) => {
            const relay: Array<{ key: string; label: string }> = [];
            const securityRelay: Array<{ key: string; label: string }> = [];

            let relayDataString = '';
            if (item.Relay !== '') {
                const relaysSource = JSON.parse(item.Relay);
                const relayData: string[] = [];
                // dtmf - relayName - unlock in homepage - unlock in takingpage -enable
                relaysSource.forEach((el: any) => {
                    relayData.push(`${el.dtmf},${el.name},${el.showHome},${el.showTalking},${el.enable}`);
                });
                relayDataString = relayData.join(';');
            }
            relayDataString.split(';').forEach((item2, index) => {
                if (item2.split(',')[4] === '1') {
                    relay.push({
                        key: index.toString(),
                        label: item2.split(',')[1]
                    });
                }
            });
            if (item.SecurityRelay) {
                let securityRelayDataString = '';
                if (item.SecurityRelay !== '') {
                    const securityRelaySource = JSON.parse(item.SecurityRelay);
                    const securityRelayData: string[] = [];
                    // dtmf - relayName - unlock in homepage - unlock in takingpage -enable
                    securityRelaySource.forEach((el: any) => {
                        securityRelayData.push(`${el.dtmf},${el.name},${el.showHome},${el.showTalking},${el.enable}`);
                    });
                    securityRelayDataString = securityRelayData.join(';');
                }
                securityRelayDataString.split(';').forEach((item2, index) => {
                    if (item2.split(',')[4] === '1') {
                        securityRelay.push({
                            key: index.toString(),
                            label: item2.split(',')[1]
                        });
                    }
                });
            }

            if (filter(item)) {
                if (!checkArrayFilter.value[item.MAC]) {
                    checkArrayFilter.value[item.MAC] = {
                        Relay: [],
                        Security: []
                    };
                }
                // 需求：编辑时未选中的building设备不显示
                if (checkArrayFilter.value[item.MAC].Relay.length !== 0
                    || checkArrayFilter.value[item.MAC].Security.length !== 0) {
                    resultFilter.push(item);
                    resultFilter[resultFilter.length - 1].Relay = relay;
                    if (item.SecurityRelay) {
                        resultFilter[resultFilter.length - 1].SecurityRelay = securityRelay;
                    }
                } else {
                    const index = data.findIndex((el) => el.MAC === item.MAC);
                    const unselectDeviceUnitName = data[index].UnitName;
                    if (alreadySelectBuildDevice.findIndex((el) => el.UnitName === unselectDeviceUnitName) > -1) {
                        resultFilter.push(item);
                        resultFilter[resultFilter.length - 1].Relay = relay;
                        if (item.SecurityRelay) {
                            resultFilter[resultFilter.length - 1].SecurityRelay = securityRelay;
                        }
                    }
                }
            } else {
                result.push(item);
                result[result.length - 1].Relay = relay;
                if (!checkArray.value[item.MAC]) {
                    checkArray.value[item.MAC] = {
                        Relay: [],
                        Security: []
                    };
                }
                chooseRelayLength += (checkArray.value[item.MAC].Relay.length + checkArray.value[item.MAC].Security.length);
                // 初始化全选
                // if (checkArray.value[item.MAC].length !== relay.length) all = false;

                if (item.SecurityRelay) {
                    result[result.length - 1].SecurityRelay = securityRelay;
                    if (!relay.every((r) => checkArray.value[item.MAC].Relay.includes(r.key))
                        || !securityRelay.every((r) => checkArray.value[item.MAC].Security.includes(r.key))) {
                        all = false;
                    }
                } else if (!relay.every((r) => checkArray.value[item.MAC].Relay.includes(r.key))) {
                    all = false;
                }
            }
        });
        allCheck.value = all;
        if (chooseRelayLength === 0) allCheck.value = false;
        return result.concat(resultFilter);
    };

    // 单个relay勾选
    const choose = () => {
        let allChoose = true;
        result.forEach((item) => {
            if (typeof item.Relay !== 'string') {
                if (item.SecurityRelay && typeof item.SecurityRelay !== 'string') {
                    if (!item.Relay.every((r) => checkArray.value[item.MAC].Relay.includes(r.key))
                            || !item.SecurityRelay.every((r) => checkArray.value[item.MAC].Security.includes(r.key))) {
                        allChoose = false;
                    }
                } else if (!item.Relay.every((r) => checkArray.value[item.MAC].Relay.includes(r.key))) {
                    allChoose = false;
                }
            }
        });
        resultFilter.forEach((item) => {
            if (typeof item.Relay !== 'string') {
                if (item.SecurityRelay && typeof item.SecurityRelay !== 'string') {
                    if (!item.Relay.every((r) => checkArrayFilter.value[item.MAC].Relay.includes(r.key))
                            || !item.SecurityRelay.every((r) => checkArrayFilter.value[item.MAC].Security.includes(r.key))) {
                        allChoose = false;
                    }
                } else if (!item.Relay.every((r) => checkArrayFilter.value[item.MAC].Relay.includes(r.key))) {
                    allChoose = false;
                }
            }
        });
        allCheck.value = allChoose;
    };

    // 获取提交数据
    const getCommit = () => {
        const device: Array<{
            MAC: string;
            Relay: string;
            SecurityRelay?: string;
        }> = [];
        Object.keys(checkArray.value).forEach((mac) => {
            const relay: string[] = [];
            const securityRelay: string[] = [];
            if (checkArray.value[mac].Relay.length > 0 || checkArray.value[mac].Security.length > 0) {
                // 判断勾选的relay 是否有超过local relay长度的，超过则为security relay
                checkArray.value[mac].Security.forEach((relayItem) => {
                    securityRelay.push(relayItem);
                });
                checkArray.value[mac].Relay.forEach((relayItem) => {
                    relay.push(relayItem);
                });
                // 不存在security relay勾选不保存
                const deviceParam = securityRelay.length > 0 ? {
                    MAC: mac,
                    Relay: relay.join(';'),
                    SecurityRelay: securityRelay.join(';')
                } : {
                    MAC: mac,
                    Relay: relay.join(';')
                };
                device.push(deviceParam);
            }
        });

        Object.keys(checkArrayFilter.value).forEach((mac) => {
            const relay: string[] = [];
            const securityRelay: string[] = [];
            if (checkArrayFilter.value[mac].Relay.length > 0 || checkArrayFilter.value[mac].Security.length > 0) {
                // 判断勾选的relay 是否有超过local relay长度的，超过则为security relay
                checkArrayFilter.value[mac].Security.forEach((relayItem) => {
                    securityRelay.push(relayItem);
                });
                checkArrayFilter.value[mac].Relay.forEach((relayItem) => {
                    relay.push(relayItem);
                });
                // 不存在security relay勾选不保存
                const deviceParam = securityRelay.length > 0 ? {
                    MAC: mac,
                    Relay: relay.join(';'),
                    SecurityRelay: securityRelay.join(';')
                } : {
                    MAC: mac,
                    Relay: relay.join(';')
                };
                device.push(deviceParam);
            }
        });
        return device;
    };

    return {
        allCheck,
        checkArray,
        changeAllCheck,
        checkArrayFilter,
        setCheck,
        dealList,
        choose,
        getCommit,
        alreadySelectPubDevice,
        alreadySelectBuildDevice,
        allDeviceList
    };
};

export default init;
