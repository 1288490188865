import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-align-left" }
const _hoisted_2 = {
  key: 0,
  class: "display-flex"
}
const _hoisted_3 = {
  key: 1,
  style: {"display":"flex"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_cus_option = _resolveComponent("cus-option")
  const _component_cus_select = _resolveComponent("cus-select")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_time_picker = _resolveComponent("el-time-picker")
  const _component_el_form = _resolveComponent("el-form")
  const _component_device_icon = _resolveComponent("device-icon")
  const _component_device_status = _resolveComponent("device-status")
  const _component_list = _resolveComponent("list")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_form, {
      class: "set-page-form-width",
      ref: "formRef",
      model: _ctx.formData,
      rules: _ctx.rules,
      "label-position": _ctx.$formLabelPosition
    }, {
      default: _withCtx(() => [
        (_ctx.type != 'self')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: _ctx.$wordList.ProperAllTextAccessGroupName,
              prop: "Name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.Name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Name = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, {
          label: _ctx.$wordList.ProperAllTextRepeats,
          prop: "SchedulerType"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cus_select, {
              modelValue: _ctx.formData.SchedulerType,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.SchedulerType = $event)),
              style: {"width":"100%"},
              onChange: _ctx.changeSchedulerType
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.repeatLabels, (item, index) => {
                  return (_openBlock(), _createBlock(_component_cus_option, {
                    key: index,
                    value: index.toString(),
                    label: item
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }, 8, ["label"]),
        (_ctx.formData.SchedulerType == 2)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: _ctx.$wordList.NavInHtmlDay
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox_group, {
                  modelValue: _ctx.formData.DateFlag,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formData.DateFlag = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.weekList, (item, index) => {
                      return (_openBlock(), _createBlock(_component_el_checkbox, {
                        class: "min-width100px",
                        key: item,
                        label: index.toString()
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        (_ctx.formData.SchedulerType == 0)
          ? (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
              _createVNode(_component_el_form_item, {
                label: _ctx.$wordList.TabelTmpKeyInHtmlBeginTime,
                prop: "StartDay"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.formData.StartDay,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formData.StartDay = $event)),
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$wordList.TabelTmpKeyInHtmlEndTime,
                prop: "StopDay"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.formData.StopDay,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.formData.StopDay = $event)),
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ], 64))
          : (_openBlock(), _createBlock(_Fragment, { key: 3 }, [
              _createVNode(_component_el_form_item, {
                label: _ctx.$wordList.TmpKeyManageListTanleBeginTime,
                prop: "StartTime"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_time_picker, {
                    modelValue: _ctx.formData.StartTime,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.formData.StartTime = $event)),
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$wordList.TabelTmpKeyInHtmlEndTime,
                prop: "StopTime"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_time_picker, {
                    modelValue: _ctx.formData.StopTime,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.formData.StopTime = $event)),
                    style: {"width":"100%"}
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ], 64))
      ]),
      _: 1
    }, 8, ["model", "rules", "label-position"]),
    _createVNode(_component_list, {
      class: "set-page-list-width",
      headers: _ctx.headers,
      update: _ctx.update,
      requestData: _ctx.requestDataType,
      action: false,
      showCheck: false,
      dealList: _ctx.dealList
    }, {
      TypeSlot: _withCtx((data) => [
        _createVNode(_component_device_icon, {
          class: "vertical-align-middle",
          type: Number(data.scope.Type)
        }, null, 8, ["type"])
      ]),
      StatusSlot: _withCtx((data) => [
        _createVNode(_component_device_status, {
          class: "vertical-align-middle",
          type: Number(data.scope.Status)
        }, null, 8, ["type"])
      ]),
      RelaySlotHeader: _withCtx((data) => [
        _createVNode(_component_el_checkbox, {
          modelValue: _ctx.allCheck,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.allCheck = $event)),
          onChange: _ctx.changeAllCheck
        }, null, 8, ["modelValue", "onChange"]),
        _createTextVNode(" " + _toDisplayString(data.scope.label), 1)
      ]),
      RelaySlot: _withCtx((data) => [
        _createVNode("div", _hoisted_1, [
          (_ctx.checkArray[data.scope.MAC])
            ? (_openBlock(), _createBlock("div", _hoisted_2, [
                _createVNode(_component_el_checkbox_group, {
                  modelValue: _ctx.checkArray[data.scope.MAC].Relay,
                  "onUpdate:modelValue": ($event: any) => (_ctx.checkArray[data.scope.MAC].Relay = $event),
                  onChange: _ctx.choose,
                  class: data.scope.Relay.length > 0 ? 'margin-right10px' : ''
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(data.scope.Relay, (checkItem) => {
                      return (_openBlock(), _createBlock(_Fragment, null, [
                        (!(data.scope.Grade == 2 && _ctx.type == 'common' && _ctx.id))
                          ? (_openBlock(), _createBlock(_component_el_checkbox, {
                              class: "margin-right10px",
                              label: checkItem.key,
                              key: checkItem
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(checkItem.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "class"]),
                _createVNode(_component_el_checkbox_group, {
                  modelValue: _ctx.checkArray[data.scope.MAC].Security,
                  "onUpdate:modelValue": ($event: any) => (_ctx.checkArray[data.scope.MAC].Security = $event),
                  onChange: _ctx.choose
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(data.scope.SecurityRelay, (checkItem) => {
                      return (_openBlock(), _createBlock(_Fragment, null, [
                        (!(data.scope.Grade == 2 && _ctx.type == 'common' && _ctx.id))
                          ? (_openBlock(), _createBlock(_component_el_checkbox, {
                              class: "margin-right10px",
                              label: checkItem.key,
                              key: checkItem
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(checkItem.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.checkArrayFilter[data.scope.MAC])
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode(_component_el_checkbox_group, {
                  modelValue: _ctx.checkArrayFilter[data.scope.MAC].Relay,
                  "onUpdate:modelValue": ($event: any) => (_ctx.checkArrayFilter[data.scope.MAC].Relay = $event),
                  onChange: _ctx.choose
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(data.scope.Relay, (checkItem) => {
                      return (_openBlock(), _createBlock(_Fragment, null, [
                        ((data.scope.Grade == 2 && _ctx.type == 'common' && _ctx.id))
                          ? (_openBlock(), _createBlock(_component_el_checkbox, {
                              class: "margin-right10px",
                              key: checkItem,
                              label: checkItem.key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(checkItem.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"]),
                _createVNode(_component_el_checkbox_group, {
                  modelValue: _ctx.checkArrayFilter[data.scope.MAC].Security,
                  "onUpdate:modelValue": ($event: any) => (_ctx.checkArrayFilter[data.scope.MAC].Security = $event),
                  onChange: _ctx.choose
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(data.scope.SecurityRelay, (checkItem) => {
                      return (_openBlock(), _createBlock(_Fragment, null, [
                        ((data.scope.Grade == 2 && _ctx.type == 'common' && _ctx.id))
                          ? (_openBlock(), _createBlock(_component_el_checkbox, {
                              class: "margin-right10px margin-left10px",
                              key: checkItem,
                              label: checkItem.key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(checkItem.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["headers", "update", "requestData", "dealList"])
  ]))
}